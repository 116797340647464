import { createSlice } from '@reduxjs/toolkit'

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        value: [],
    },
    reducers: {
        clearNotifications: (state) => {
            state.value = []
        },
        setNotifications: (state, action) => {
            state.value = action.payload
        },
        updateOrAppendNotification: (state, action) => {
            const notifications = state.value
            const newNotifications = notifications.filter(item => item !== action.payload)
            newNotifications.unshift(action.payload)
            state.value = newNotifications
        },
        updateOrAppendNotifications: (state, action) => {
            const notifications = state.value
            const newNotifications = notifications.filter(item => !action.payload.includes(item))
            state.value = newNotifications.concat(action.payload.messages)
        },
    },
})

export const { clearNotifications, setNotifications, updateOrAppendNotification, updateOrAppendNotifications } = notificationsSlice.actions

export default notificationsSlice.reducer