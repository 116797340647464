import { createSlice } from '@reduxjs/toolkit'

export const messagesSlice = createSlice({
    name: 'messages',
    initialState: {
        value: {},
    },
    reducers: {
        clearMessages: (state) => {
            state.value = {}
        },
        setMessages: (state, action) => {
            const messages = state.value
            messages[action.payload.chatUuid] = action.payload.messages
            state.value = messages
        },
        clearMessagesByChats: (state, action) => {
            const messages = state.value
            for (const chat of action.payload) {
                delete messages[chat.uuid]
            }
            state.value = messages
        },
        updateUploadedPhoto: (state, action) => {
            const messages = state.value
            if (messages[action.payload.message.chatUuid] === undefined) {
                messages[action.payload.message.chatUuid] = []
            }

            const chatMessages = messages[action.payload.message.chatUuid]
            .filter(item => item.id !== action.payload.message.id && item.id !== action.payload.tempUploadId)

            chatMessages.unshift(action.payload.message)
            messages[action.payload.message.chatUuid] = chatMessages
            state.value = messages
        },
        updateOrAppendMessage: (state, action) => {
            const messages = state.value
            if (messages[action.payload.chatUuid] === undefined) {
                messages[action.payload.chatUuid] = []
            }

            const chatMessages = messages[action.payload.chatUuid].filter(item => item.id !== action.payload.id)
            chatMessages.unshift(action.payload)
            messages[action.payload.chatUuid] = chatMessages
            state.value = messages
        },
        updateOrAppendMessages: (state, action) => {
            const messageIds = action.payload.messages.map(item => item.id)
            const messages = state.value
            if (messages[action.payload.chatUuid] === undefined) {
                messages[action.payload.chatUuid] = []
            }

            const chatMessages = messages[action.payload.chatUuid].filter(item => !messageIds.includes(item.id))
            messages[action.payload.chatUuid] = action.payload.messages.concat(chatMessages)
            state.value = messages
        },
        deleteMessagesBySenders: (state, action) => {
            const messages = state.value
            for (const sender of action.payload) {
                if (messages[sender.chat.uuid] !== undefined) {
                    messages[sender.chat.uuid] = messages[sender.chat.uuid].filter(item => item.sender.id !== sender.id)
                }
            }
            state.value = messages
        },
    },
})

export const { clearMessages, setMessages, clearMessagesByChats, updateUploadedPhoto, updateOrAppendMessage, updateOrAppendMessages, deleteMessagesBySenders } = messagesSlice.actions

export default messagesSlice.reducer

export const clearMessagesByChatsAndSenders = (deletedChats) => (dispatch) => {
    if (deletedChats.chats.length > 0) {
        dispatch(clearMessagesByChats(deletedChats.chats))
    }
    
    if (deletedChats.senders.length > 0) {
        dispatch(deleteMessagesBySenders(deletedChats.senders))
    }
}