import React from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'src/components/Modal'
import { closeRequestNotification } from 'src/redux/slices/openRequestNotificationSlice'
import ls from 'src/utils/localStorage'
import helpers from 'src/utils/helpers'

function RequestNotification() {
    const dispatch = useDispatch()

    const handleCancel = () => {
        dispatch(closeRequestNotification())
    }

    const handleOk = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then(function(reg) {
                reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: helpers.urlBase64ToUint8Array(
                        'BNBkDRjYepEJcVQwtwdyh8ow2g8zay-_Cr5FpnBVZ28nYgE52GM2CbAa9H6CkcxGYhmB6Y8ABlj9wco6RC5LU3w'
                    )
                }).then(function(sub) {
                    const res = sub.toJSON()
                    window.http.post('/users/notify', {
                        endpoint: res.endpoint,
                        token: res.keys.auth,
                        key: res.keys.p256dh,
                        userUuid: ls.getUuid(),
                    }).then((response) => {
                        ls.setPushNotificationEnabled(1)
                        console.log("Notification subscribed")
                    }).catch((error) => {
                        ls.setPushNotificationEnabled(0)
                        alert(error)
                    })
                }).catch(function(e) {
                    ls.setPushNotificationEnabled(0)
                    if (Notification.permission === 'denied') {
                        console.warn('Permission for notifications was denied');
                    } else {
                        console.error('Unable to subscribe to push', e);
                    }
                })
            })
        }

        handleCancel()
    }

    return (
        <Modal>
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0">
                        <h3 className="text-lg leading-6 font-medium text-white" id="modal-headline">
                            Enable Notification?
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm mt-3 text-gray-300">
                                By enabling notification, you can get new<br/>message notifacation, just like other chat apps.<br/>You can always disable it later.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-800 px-2 pb-3 flex justify-between">
                <button
                    type="button"
                    className="w-1/2 inline-flex justify-center rounded-md border border-transparent shadow-sm mx-2 px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none sm:text-sm"
                    onClick={handleCancel}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="w-1/2 inline-flex justify-center rounded-md border border-transparent shadow-sm mx-2 px-4 py-2 bg-indigo-900 text-base font-medium text-white hover:bg-indigo-800 focus:outline-none sm:text-sm"
                    onClick={handleOk}
                >
                    Enable
                </button>
            </div>
        </Modal>      
    )
}

export default RequestNotification
