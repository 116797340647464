import { createSlice } from '@reduxjs/toolkit'

export const openNotificationsSlice = createSlice({
    name: 'openNotifications',
    initialState: {
        value: false,
    },
    reducers: {
        openNotifications: (state) => {
            state.value = true
        },
        closeNotifications: (state) => {
            state.value = false
        },
    },
})

export const { openNotifications, closeNotifications } = openNotificationsSlice.actions

export default openNotificationsSlice.reducer