import { createSlice } from '@reduxjs/toolkit'

export const openPhotoPreviewSlice = createSlice({
    name: 'openPhotoPreview',
    initialState: {
        value: undefined,
    },
    reducers: {
        openPhotoPreview: (state, action) => {
            state.value = action.payload
        },
        closPhotoPreview: (state) => {
            state.value = undefined
        },
    },
})

export const { openPhotoPreview, closPhotoPreview } = openPhotoPreviewSlice.actions

export default openPhotoPreviewSlice.reducer