import { createSlice } from '@reduxjs/toolkit'

export const chatsSlice = createSlice({
    name: 'chats',
    initialState: {
        value: [],
    },
    reducers: {
        clearChats: (state) => {
            state.value = []
        },
        setChats: (state, action) => {
            state.value = action.payload
        },
        addOrUpdateChat: (state, action) => {
            const chats = state.value.filter(item => item.uuid !== action.payload.uuid)
            chats.push(action.payload)
            state.value = chats
        },
        addOrUpdateChats: (state, action) => {
            const chatUuids = action.payload.map(item => item.uuid)
            const chats = state.value.filter(item => !chatUuids.includes(item.uuid))
            state.value = chats.concat(action.payload)
        },
        deleteChat: (state, action) => {
            const chats = state.value.filter(item => item.uuid !== action.payload.uuid)
            state.value = chats
        },
        deleteChats: (state, action) => {
            const uuids = action.payload.map(item => item.uuid)
            const chats = state.value.filter(item => !uuids.includes(item.uuid))
            state.value = chats
        },
    },
})

export const { clearChats, setChats, addOrUpdateChat, addOrUpdateChats, deleteChat, deleteChats } = chatsSlice.actions

export default chatsSlice.reducer

export const deleteUpdateChats = (deletedChats) => (dispatch) => {
    if (deletedChats.chats.length > 0) {
        dispatch(deleteChats(deletedChats.chats))
    }

    if (deletedChats.senders.length > 0) {
        dispatch(addOrUpdateChats(deletedChats.senders.map(item => item.chat)))
    }    
}