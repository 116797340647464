import { createSlice } from '@reduxjs/toolkit'

export const openDeleteAllChatsSlice = createSlice({
    name: 'openDeleteAllChats',
    initialState: {
        value: false,
    },
    reducers: {
        openDeleteAllChats: (state) => {
            state.value = true
        },
        closeDeleteAllChats: (state) => {
            state.value = false
        },
    },
})

export const { openDeleteAllChats, closeDeleteAllChats } = openDeleteAllChatsSlice.actions

export default openDeleteAllChatsSlice.reducer