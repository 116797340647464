import { createSlice } from '@reduxjs/toolkit'

export const openDeleteThisChatSlice = createSlice({
    name: 'openDeleteThisChat',
    initialState: {
        value: undefined,
    },
    reducers: {
        openDeleteThisChat: (state, action) => {
            state.value = action.payload
        },
        closeDeleteThisChat: (state) => {
            state.value = undefined
        },
    },
})

export const { openDeleteThisChat, closeDeleteThisChat } = openDeleteThisChatSlice.actions

export default openDeleteThisChatSlice.reducer