import React from 'react'
import PropTypes from 'prop-types'

function Loading({iconSize, msg, msgSize}) {
    if (msg === null) {
        return (
            <div className={`${iconSize} animate-spin`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-center h-full flex-wrap content-center text-gray-300">
            <div className={`${iconSize} animate-spin`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
            <p className={`text-center mt-5 ${msgSize}`}>{msg}...</p>
        </div>
    )
}

Loading.propTypes = {
    iconSize: PropTypes.string,
    msg: PropTypes.string,
    msgSize: PropTypes.string
}

Loading.defaultProps = {
    iconSize: 'w-1/5',
    msg: null,
    msgSize: 'text-base'
};

export default Loading
