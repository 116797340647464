import { createSlice } from '@reduxjs/toolkit'

export const activeChatUuidSlice = createSlice({
    name: 'activeChatUuid',
    initialState: {
        value: undefined,
    },
    reducers: {
        clearActiveChatUuid: (state) => {
            state.value = undefined
        },
        setActiveChatUuid: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { clearActiveChatUuid, setActiveChatUuid } = activeChatUuidSlice.actions

export default activeChatUuidSlice.reducer