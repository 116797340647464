import React, {
    lazy,
    Suspense,
    Fragment
} from 'react'
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom'
import Loading from 'src/components/Loading'
import AppLayout from 'src/layouts/AppLayout'
  
const routesConfig = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/pages/Error'))
    }, {
        path: '*',
        layout: AppLayout,
        routes: [
            {
                exact: true,
                path: '/join/:chatUuid?/:pin?',
                component: lazy(() => import('src/pages/Join'))
            },
            {
                exact: true,
                path: '/:chatUuid?',
                component: lazy(() => import('src/pages/App'))
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    },
]
  
// eslint-disable-next-line multiline-ternary
const renderRoutes = (routes) => (routes ? (
    <Suspense fallback={<Loading msg="Loading" />}>
        <Switch>
        {routes.map((route, i) => {
            const Layout = route.layout || Fragment
            const Component = route.component

            return (
            <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                <Layout>
                    {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
                )}
            />
            )
        })}
        </Switch>
    </Suspense>
) : null)

function Routes () {
    return renderRoutes(routesConfig);
}

export default Routes
  