import * as SecureLS from 'secure-ls'
import { v4 as uuidv4 } from 'uuid'

function getLs() {
    return new SecureLS()
}

function isVisited() {
    return getLs().get('uuid') !== ''
}

function setUuid() {
    getLs().set('uuid', uuidv4())
}

function getUuid() {
    return getLs().get('uuid')
}

function hasAskedNotification() {
    return getLs().get('pushNotificationEnabled') !== ''
}

function isPushNotificationEnabled() {
    if (hasAskedNotification()) {
        return (parseInt(getLs().get('pushNotificationEnabled')) === 1)
    }
    return false
}

function setPushNotificationEnabled(enabled) {
    getLs().set('pushNotificationEnabled', enabled)
}

function isBackendVersionLower(version) {
    const backendVersion = getLs().get('wbv')
    if (backendVersion === ''
    || (parseFloat(backendVersion) < parseFloat(version))) {
        getLs().set('wbv', version)
        return true
    }

    return false
}

//////////////////////////////////////////////////
//
// Avatars
//
//////////////////////////////////////////////////

function setAvatars(avatars) {
    if (!(avatars instanceof Array)
    || avatars.length === 0
    || typeof avatars[0] !== "object") {
        console.error("setAvatars => invalid avatars")
    }

    getLs().set('avatars', avatars)
}

function getAvatars() {
    return (getLs().get('avatars') !== '')
    ? getLs().get('avatars')
    : []
}

//////////////////////////////////////////////////
//
// Members
//
//////////////////////////////////////////////////

function setMembers(members) {
    if (!(members instanceof Array)
    || members.length === 0
    || typeof members[0] !== "object") {
        console.error("setMembers => invalid members")
    }

    getLs().set('members', members)
}

function getMembers() {
    return (getLs().get('members') !== '')
    ? getLs().get('members')
    : []
}

function addOrUpdateMember(member) {
    if (typeof member !== "object") {
        console.error("addOrUpdateMember => invalid member")
    }

    const members = getMembers().filter(item => item.id !== member.id)
    members.push(member)
    setMembers(members)
}

function detachChatFromMember(chatUuid) {
    for (const member of getMembers()) {
        const chats = member.chats.filter(pChat => pChat.uuid !== chatUuid)
        member.chats = chats
        addOrUpdateMember(member)
    }
}

//////////////////////////////////////////////////
//
// Chats
//
//////////////////////////////////////////////////

function hasChat(chatUuid) {
    return (getChats().find(chat => chat.uuid === chatUuid) !== undefined)
}

function setChats(chats) {
    if (!(chats instanceof Array)
    || chats.length === 0
    || typeof chats[0] !== "object") {
        console.error("setChats => invalid chats")
    }

    getLs().set('chats', JSON.stringify(chats))
}

function getChats() {
    return (getLs().get('chats') !== '')
    ? JSON.parse(getLs().get('chats'))
    : []
}

function addOrUpdateChat(chat) {
    if (typeof chat !== "object") {
        console.error("addOrUpdateChat => invalid chat")
    }

    const chats = getChats().filter(item => item.uuid !== chat.uuid)
    chats.push(chat)
    getLs().set('chats', JSON.stringify(chats))
}

function deleteChat(chat) {
    if (typeof chat !== "object") {
        console.error("deleteChat => invalid chat")
    }

    clearMessages(chat.uuid)
    detachChatFromMember(chat.uuid)

    const chats = getChats().filter(item => item.uuid !== chat.uuid)
    getLs().set('chats', JSON.stringify(chats))
}

function deleteChats(deleteChats) {
    if (!(deleteChats instanceof Array)) {
        console.error("deleteChats => invalid deleteChats")
    }

    if (deleteChats.length > 0) {
        const uuids = deleteChats.map(item => item.uuid)
        for (const uuid of uuids) {
            clearMessages(uuid)
            detachChatFromMember(uuid)
        }
    
        const chats = getChats().filter(item => !uuids.includes(item.uuid))
        getLs().set('chats', JSON.stringify(chats))
    }
}

//////////////////////////////////////////////////
//
// Message
//
//////////////////////////////////////////////////

function setMessages(chatUuid, messages) {
    if (typeof chatUuid !== "string"
    || !(messages instanceof Array)) {
        console.error("setMessages => invalid inputs")
    }

    getLs().set(chatUuid, JSON.stringify(messages))
}

function getMessages(chatUuid) {
    return (getLs().get(chatUuid) !== '')
    ? JSON.parse(getLs().get(chatUuid))
    : []
}

function clearMessages(chatUuid) {
    getLs().remove(chatUuid)
}

function updateUploadedPhoto(tempUploadId, message) {
    if (typeof tempUploadId !== "string"
    || typeof message !== "object") {
        console.error("updateUploadedPhoto => invalid input")
    }

    const messages = getMessages(message.chatUuid).filter(item => item.id !== message.id && item.id !== tempUploadId)
    messages.unshift(message)
    getLs().set(message.chatUuid, JSON.stringify(messages))
}

function updateOrAppendMessage(message) {
    if (typeof message !== "object") {
        console.error("updateOrAppendMessage => invalid input")
    }

    const messages = getMessages(message.chatUuid).filter(item => item.id !== message.id)
    messages.unshift(message)
    getLs().set(message.chatUuid, JSON.stringify(messages))
}

function updateOrAppendMessages(chatUuid, messages) {
    const messageIds = messages.map(item => item.id)
    const chatMessages = getMessages(chatUuid).filter(item => !messageIds.includes(item.id))
    getLs().set(chatUuid, JSON.stringify(chatMessages.concat(messages)))
}

function removeMessage(chatUuid, messageId) {
    if (typeof chatUuid !== "string"
    || typeof messageId !== "number") {
        console.error("removeMessage => invalid input")
    }

    const messages = getMessages(chatUuid).filter(item => item.id !== messageId)
    getLs().set(chatUuid, JSON.stringify(messages))
}

function deleteMessagesBySenders(senders) {
    if (!(senders instanceof Array)) {
        console.error("deleteMessagesBySenders => invalid senders")
    }

    for (const sender of senders) {
        const messages = getMessages(sender.chat.uuid).filter(item => item.sender.id !== sender.id)
        setMessages(sender.chat.uuid, messages)
    }
}

//////////////////////////////////////////////////
//
// Chat
//
//////////////////////////////////////////////////

// function setChat(chatId, chatUser, msgs) {
//     if (typeof chatId !== "string"
//     || typeof chatUser !== "object"
//     || !(msgs instanceof Array)) {
//         console.error("setChat => invalid input")
//     }

//     getLs().set(chatId, JSON.stringify({chatUser, msgs}))
// }

// function clearChat(chatId) {
//     if (typeof chatId !== "string") {
//         console.error("clearChat => invalid input")
//     }

//     getLs().remove(chatId)
// }

// function setChatUser(chatId, chatUser) {
//     if (typeof chatId !== "string"
//     || typeof chatUser !== "object") {
//         console.error("setChatUser => invalid input")
//     }

//     const chat = JSON.parse(getLs().get(chatId))
//     chat.chatUser = chatUser
//     getLs().set(chatId, JSON.stringify(chat))
// }

// function getChatUser(chatId) {
//     if (typeof chatId !== "string") {
//         console.error("getChatUser => invalid input")
//     }

//     const chat = JSON.parse(getLs().get(chatId))
//     return chat.chatUser
// }

// function setChatMsgs(chatId, msgs) {
//     if (typeof chatId !== "string"
//     || !(msgs instanceof Array)) {
//         console.error("setChatMsgs => invalid input")
//     }

//     const chat = JSON.parse(getLs().get(chatId))
//     chat.msgs = msgs
//     getLs().set(chatId, JSON.stringify(chat))
// }

// function getChatMsgs(chatId) {
//     if (typeof chatId !== "string") {
//         console.error("getChatMsgs => invalid input")
//     }

//     return JSON.parse(getLs().get('chatId').get('msgs'))
// }

// function appendMsg(chatId, chatUserId, msgId, msg) {
//     if (typeof chatId !== "string"
//     || typeof chatUserId !== "number"
//     || typeof msgId !== "number"
//     || typeof msg !== "string") {
//         console.error("appendMsg => invalid input")
//     }

//     const chat = JSON.parse(getLs().get(chatId))
//     chat.msgs.push({chatUserId, msgId, msg})
//     getLs().set(chatId, JSON.stringify(chat))
// }

// function removeMsg(chatId, msgId) {
//     if (typeof chatId !== "string"
//     || typeof msgId !== "number") {
//         console.error("removeMsg => invalid input")
//     }

//     const chat = JSON.parse(getLs().get(chatId))
//     chat.msgs = chat.msgs.filter(msg => msg.msgId !== msgId)
//     getLs().set(chatId, JSON.stringify(chat))
// }

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    isVisited,
    setUuid,
    getUuid,
    hasAskedNotification,
    isPushNotificationEnabled,
    setPushNotificationEnabled,
    isBackendVersionLower,

    setAvatars,
    getAvatars,

    setMembers,
    getMembers,
    addOrUpdateMember,
    detachChatFromMember,

    hasChat,
    setChats,
    getChats,
    addOrUpdateChat,
    deleteChat,
    deleteChats,

    setMessages,
    getMessages,
    clearMessages,
    updateUploadedPhoto,
    updateOrAppendMessage,
    updateOrAppendMessages,
    removeMessage,
    deleteMessagesBySenders,
}
