import { createSlice } from '@reduxjs/toolkit'

export const openChatNewSlice = createSlice({
    name: 'openChatNew',
    initialState: {
        value: false,
    },
    reducers: {
        openChatNew: (state) => {
            state.value = true
        },
        closeChatNew: (state) => {
            state.value = false
        },
    },
})

export const { openChatNew, closeChatNew } = openChatNewSlice.actions

export default openChatNewSlice.reducer