import React from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import ls from 'src/utils/localStorage'
import helpers from 'src/utils/helpers'
import track from 'src/utils/analytics'
import { closeDeleteAllChats } from 'src/redux/slices/openDeleteAllChatsSlice'
import { closeDeleteThisChat } from 'src/redux/slices/openDeleteThisChatSlice'
import { deleteChats } from 'src/redux/slices/chatsSlice'
import Modal from 'src/components/Modal'

function DeleteAllChats({deleteChat}) {
    const dispatch = useDispatch()
    const history = useHistory()
    const isDeleteAllChats = deleteChat === undefined
    const chats = useSelector((state) => state.chats.value)
    const deletes = !isDeleteAllChats
    ? [deleteChat]
    : chats.map((chat) => {
        const member = helpers.findMember(chat.uuid)
        const memberChat = helpers.findMemberChat(chat.uuid)
        if (memberChat.is_admin) {
            return {...memberChat, name: chat.name, pin: chat.pin, memberId: member.id}
        }
        return {uuid: chat.uuid, is_admin: false, name: chat.name, pin: chat.pin, memberId: member.id}
    })

    const getChatList = (chats, isAdmin) => {
        return chats.filter((chat) => chat.is_admin === isAdmin)
        .map((chat, index) => (
            <div key={index} className="flex items-center justify-center text-sm text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                {chat.name}
            </div>
        ))
    }

    const handleCancel = () => {
        if (isDeleteAllChats) {
            dispatch(closeDeleteAllChats())
        } else {
            dispatch(closeDeleteThisChat())
        }
    }

    const handleDeleteAll = () => {
        track.event('delete-all-chat', {'event_category': 'chat'})
        window.http.delete('/chat/batch', {
            data: {chats: deletes}
        }).then((response) => {
            helpers.leaveDeletedChannels(response.data.deletedChats, [...chats])
            ls.deleteChats(response.data.deletedChats)
            dispatch(deleteChats(response.data.deletedChats))
        }).catch((error) => {
            alert(error)
        }).then(() => {
            if (isDeleteAllChats) {
                dispatch(closeDeleteAllChats())
            } else {
                dispatch(closeDeleteThisChat())
            }
            history.push('/')
        })
    }

    let title = <>Delete All Chats</>
    let description = <>This will delete chat(s) below which created by you<br/>from you and your friend's devices</>
    let subDescription = <>If you wish to delete a single chat, goto its top right<br/>dropdown and select <b>Delete This Chat</b> instead.</>
    let isAdmin = true
    let btnLabel = "Delete All"
    if (deletes && deletes.length === 1) {
        title = <>Delete Chat</>
        description = deletes[0].is_admin
        ? <>This will delete chat below which created by you<br/>from you and your friend's devices</>
        : <>This will delete chat below which you've<br/>joined from your device</>
        subDescription = <></>
        isAdmin = deletes[0].is_admin
        btnLabel = "Delete"
    }

    return (
        <Modal>
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 divide-y divide-gray-600 divide-dashed">
                <div>
                    <h3 className="text-center text-lg leading-6 font-medium text-white" id="modal-headline">
                        {title}
                    </h3>
                    <p className="mt-2 text-center text-sm text-gray-300">
                        {description}
                    </p>
                    <div className="my-5">
                        {deletes && getChatList(deletes, isAdmin)}
                    </div>
                </div>
                {deletes && deletes.length > 1 && (
                    <div>
                        <p className="mt-2 text-center text-sm text-gray-300">
                            And will delete chat(s) below which you've<br/>joined from your device
                        </p>
                        <div className="my-5">
                            {deletes && getChatList(deletes, false)}
                        </div>
                    </div>
                )}
                <div>
                    <p className="my-3 text-center text-sm text-gray-300">
                        All message(s) that you've sent to the chat(s)<br/>will be deleted from you and your friend's devices.<br/>{subDescription}
                    </p>
                </div>
            </div>
            <div className="bg-gray-800 px-2 pb-3 flex justify-between">
                <button onClick={handleCancel} type="button" className="w-1/2 inline-flex justify-center rounded-md border border-transparent shadow-sm mx-2 px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none sm:text-sm">
                    Cancel
                </button>
                <button onClick={handleDeleteAll} type="button" className="w-1/2 inline-flex justify-center rounded-md border border-transparent shadow-sm mx-2 px-4 py-2 bg-indigo-900 text-base font-medium text-white hover:bg-indigo-800 focus:outline-none sm:text-sm">
                    {btnLabel}
                </button>
            </div>
        </Modal>            
    )
}

DeleteAllChats.propTypes = {
    deleteChat: PropTypes.object
}

DeleteAllChats.defaultProps = {
    deleteChat: undefined
};

export default DeleteAllChats
