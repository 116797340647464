import { createSlice } from '@reduxjs/toolkit'

export const openRequestNotificationSlice = createSlice({
    name: 'openRequestNotification',
    initialState: {
        value: false,
    },
    reducers: {
        openRequestNotification: (state) => {
            state.value = true
        },
        closeRequestNotification: (state) => {
            state.value = false
        },
    },
})

export const { openRequestNotification, closeRequestNotification } = openRequestNotificationSlice.actions

export default openRequestNotificationSlice.reducer