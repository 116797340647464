import { createSlice } from '@reduxjs/toolkit'

export const openChatJoinSlice = createSlice({
    name: 'openChatJoin',
    initialState: {
        value: false,
    },
    reducers: {
        openChatJoin: (state) => {
            state.value = true
        },
        closeChatJoin: (state) => {
            state.value = false
        },
    },
})

export const { openChatJoin, closeChatJoin } = openChatJoinSlice.actions

export default openChatJoinSlice.reducer