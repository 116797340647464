import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { closeChatInvite } from 'src/redux/slices/openChatInviteSlice'
import Modal from 'src/components/Modal'
import Loading from 'src/components/Loading'
import helpers from 'src/utils/helpers'

function ChatInvite({value}) {
    const dispatch = useDispatch()
    const chats = useSelector((state) => state.chats.value)
    const activeChatUuid = useSelector((state) => state.activeChatUuid.value)
    const activeChat = helpers.getActiveChat(chats, activeChatUuid)
    const link = `${process.env.REACT_APP_PUBLIC_URL}/join/${activeChat.uuid}/${activeChat.pin}`
    const QRCode = lazy(() => import('react-qr-code'))

    const handleOk = () => {
        dispatch(closeChatInvite())
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(link)
        alert("Invitation link copied!")
    }

    return (
        <Modal>
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 divide-y divide-gray-600 divide-dashed">
                <div>
                    <h3 className="text-center text-lg leading-6 font-medium text-white" id="modal-headline">
                        Invite to Chat
                    </h3>
                    <div className="mt-2">
                        <p className="text-center text-sm text-gray-300">
                            Ask your friend to join <font className="font-bold">{activeChat.name}</font> with:
                        </p>
                    </div>
                    <div className="my-8">
                        <p className="text-center text-sm mt-3 text-gray-300">
                            <font className="font-bold mx-2">Chat ID:</font>
                            <font className="font-bold bg-gray-700 p-2 rounded-md">{activeChat.uuid}</font>
                            <font className="font-bold mx-2">PIN:</font>
                            <font className="font-bold bg-gray-700 p-2 rounded-md">{activeChat.pin}</font>
                        </p>
                    </div>
                </div>
                <div className="text-gray-300 flex flex-col">
                    <p className="mt-8 text-center text-sm text-gray-300">Or click below link to copy and share:</p>
                    <p onClick={handleCopyLink} className="mb-8 text-center text-sm text-blue-400 hover:text-blue-300 cursor-pointer">{link}</p>
                </div>
                <div className="text-gray-300 flex flex-col">
                    <p className="mt-4 mb-2 text-center text-sm text-gray-300">Or let him scan QR to join:</p>
                    <div className="mb-4 flex justify-center">
                        <Suspense fallback={<Loading msg="Loading" iconSize="w-full" />}>
                            <QRCode value={link} />
                        </Suspense>
                    </div>
                </div>
            </div>
            <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button onClick={handleOk} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-900 text-base font-medium text-white hover:bg-indigo-800 focus:outline-none sm:text-sm">
                    OK
                </button>
            </div>
        </Modal>            
    )
}

ChatInvite.propTypes = {
    value: PropTypes.object
}

export default ChatInvite
