import React, { useState, useRef, useEffect } from 'react'
import { useField } from 'formik';
import PropTypes from 'prop-types'

function SelectImage({items, disabled, ...props}) {
    const [open, setOpen] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helpers] = useField(props);
    const { value } = meta;
    const { setValue, setTouched } = helpers;
    const ref = useRef(null)

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    }

    const handleSelect = (item) => {
        setOpen(false)
        setTouched(true)
        setValue(item.id)
    }

    const getSelected = (id) => {
        return items.find(item => item.id === id)
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, [])

    return (
        <div>
            <div className="mt-1 relative">
                <button disabled={disabled} onClick={handleOpen} type="button" className="disabled:opacity-50 relative h-18 w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                    <div>
                        {value && (
                            <span className="flex justify-center">
                                <img src={getSelected(value).image} alt="" className="flex-shrink-0 h-16 w-16 rounded-full" />
                            </span>
                        )}
                        {!value && (
                            <div className="h-16 w-16">&nbsp;</div>
                        )}
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </span>
                    </div>
                </button>

                {open && (
                    <ul ref={ref} className={`z-40 absolute mt-1 w-full bg-white shadow-lg max-h-40 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`} tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                        {items.map((item) => (
                            <li key={item.id} onClick={() => handleSelect(item)} className="text-gray-900 hover:bg-indigo-200 hover:text-white cursor-default select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option" aria-selected="true">
                                <div className="flex justify-center">
                                    <img src={item.image} alt="" className="flex-shrink-0 h-16 w-16 rounded-full" />
                                </div>

                                {value && item.id === getSelected(value).id && (
                                    <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

SelectImage.propTypes = {
    items: PropTypes.array
}

export default SelectImage

