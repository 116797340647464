import React, { useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import Echo from 'laravel-echo'
import axios from 'axios'
import ls from 'src/utils/localStorage'
import helpers from 'src/utils/helpers'
import track from 'src/utils/analytics'
import { addOrUpdateChat, deleteUpdateChats } from 'src/redux/slices/chatsSlice'
import { updateOrAppendMessage, clearMessagesByChatsAndSenders } from 'src/redux/slices/messagesSlice'
import { setNotifications } from 'src/redux/slices/notificationsSlice'
import ChatNew from 'src/components/Modal/ChatNew'
import ChatJoin from 'src/components/Modal/ChatJoin'
import ChatInvite from 'src/components/Modal/ChatInvite'
import DeleteAllChats from 'src/components/Modal/DeleteAllChats'
import Notifications from 'src/components/Modal/Notifications'
import PhotoPreview from 'src/components/Modal/PhotoPreview'
import RequestNotification from 'src/components/Modal/RequestNotification'

function AppLayout({ children }) {
    const isMounted = useRef(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const chats = useSelector((state) => state.chats.value)
    const openChatNew = useSelector((state) => state.openChatNew.value)
    const openChatJoin = useSelector((state) => state.openChatJoin.value)
    const openChatInvite = useSelector((state) => state.openChatInvite.value)
    const openDeleteAllChats = useSelector((state) => state.openDeleteAllChats.value)
    const openDeleteThisChat = useSelector((state) => state.openDeleteThisChat.value)
    const openNotifications = useSelector((state) => state.openNotifications.value)
    const openPhotoPreview = useSelector((state) => state.openPhotoPreview.value)
    const openRequestNotification = useSelector((state) => state.openRequestNotification.value)

    const sendPageViewEvent = useCallback(() => {
        track.pageview({});
    }, []);

    useEffect(() => {
        sendPageViewEvent();
    }, [sendPageViewEvent]);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true
            window.Pusher = require('pusher-js');
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.REACT_APP_MIX_PUSHER_APP_KEY,
                cluster: process.env.REACT_APP_MIX_PUSHER_APP_CLUSTER,
                wsHost: process.env.REACT_APP_BASE_DOMAIN,
                wsPort: 6001,
                wssPort: 6001,
                forceTLS: true,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
            })

            window.http = axios.create({
                baseURL: process.env.REACT_APP_API_BASE_URL,
            })

            window.http.interceptors.response.use(function (response) {
                if ('serviceWorker' in navigator
                    && response.headers
                    && response.headers.wbv
                    && ls.isBackendVersionLower(response.headers.wbv)) {
                    navigator.serviceWorker.ready.then(registration => {
                        registration.update()
                    })
                }

                return response
            })

            // axios.defaults.withCredentials = true
            window.Echo.connector.pusher.connection.bind('connected', function () {
                window.http.defaults.headers.common['X-Socket-Id'] = window.Echo.socketId();
            })
        }
    }, [isMounted])

    useEffect(() => {
        if (window.Echo) {
            window.Echo.channel('chats.global')
                .listen('ChatsDeleted', (e) => {
                    if (helpers.isGivenChatsInLocalChats(e, [...chats])) {
                        helpers.leaveDeletedChannels(e.chats, [...chats])
                        ls.deleteChats(e.chats)
                        ls.deleteMessagesBySenders(e.senders)
                        dispatch(deleteUpdateChats(e))
                        dispatch(clearMessagesByChatsAndSenders(e))
                        dispatch(setNotifications(helpers.convertDeleteChatsToNotifications(e)))
                        history.push('/')
                    }
                })

            return () => {
                window.Echo.leaveChannel('chats.global')
            }
        }
    })

    useEffect(() => {
        if (window.Echo) {
            chats.forEach(chat => {
                window.Echo.channel(`chat.${chat.uuid}.${chat.pin}`)
                    .listen('MessageSent', (e) => {
                        const member = helpers.findMember(chat.uuid)
                        ls.updateOrAppendMessage(e)
                        if (e.sender.id !== member.id) {
                            window.http.put('/message/batch', {
                                messages: [{ id: e.id, member_id: member.id, status: helpers.MESSAGE_STATUS_DELIVERED }]
                            }).catch((error) => {
                                alert(error)
                            })
                        }
                        dispatch(updateOrAppendMessage(e))
                    }).listen('MessageUpdated', (e) => {
                        const member = helpers.findMember(e.chatUuid)
                        if (e.sender.id === member.id) {
                            ls.updateOrAppendMessage(e)
                            dispatch(updateOrAppendMessage(e))
                        }
                    }).listen('ChatUpdated', (e) => {
                        ls.addOrUpdateChat(e)
                        dispatch(addOrUpdateChat(e))
                    })
            })

            return () => {
                chats.forEach(chat => {
                    window.Echo.leaveChannel(`chat.${chat.uuid}.${chat.pin}`)
                })
            }
        }
    }, [chats, dispatch, history])

    return (
        <div>
            <div className="bg-gray-900 h-screen font-sans antialiased">
                <div className="lg:container lg:mx-auto lg:py-5 lg:px-16 h-full">
                    {children}
                </div>
            </div>
            {openChatNew && <ChatNew />}
            {openChatJoin && <ChatJoin />}
            {openChatInvite && <ChatInvite />}
            {openDeleteAllChats && <DeleteAllChats />}
            {openDeleteThisChat && <DeleteAllChats deleteChat={openDeleteThisChat} />}
            {openNotifications && <Notifications />}
            {openPhotoPreview && <PhotoPreview photo={openPhotoPreview} />}
            {openRequestNotification && <RequestNotification />}
        </div>
    )
}

export default AppLayout
