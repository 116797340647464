/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ls from 'src/utils/localStorage'
import track from 'src/utils/analytics'
import { useDispatch } from 'react-redux'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { addOrUpdateChat } from 'src/redux/slices/chatsSlice'
import { setActiveChatUuid } from 'src/redux/slices/activeChatUuidSlice'
import { closeChatJoin } from 'src/redux/slices/openChatJoinSlice'
import SelectImageText from 'src/components/Form/SelectImageText'
import SelectImage from 'src/components/Form/SelectImage'
import Loading from 'src/components/Loading'
import Modal from 'src/components/Modal'

function ChatJoin() {
    const dispatch = useDispatch()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [avatars, setAvatars] = useState([])
    const members = ls.getMembers().map(member => {
        return {
            id: member.id,
            image: process.env.REACT_APP_BASE_URL + member.path,
            text: member.nick
        }
    })

    useEffect(() => {
        if (ls.getAvatars().length === 0) {
            window.http.get('/chats/create')
            .then((response) => {
                const data = response.data.data.map(avatar => {return {id: avatar.id, image: process.env.REACT_APP_BASE_URL + avatar.path}})
                ls.setAvatars(data)
                setAvatars(data)
            }).catch((error) => {
                alert(error.response.data.error)
            })
        } else {
            setAvatars(ls.getAvatars())
        }
    }, [setAvatars])

    return (
        <Modal>
            <div className="px-2 py-3">
                <h3 className="text-lg leading-6 font-medium text-white text-center">
                    Join Chat
                </h3>

                <p className="mt-3 text-center text-sm text-gray-300">
                    Please setup your profile for this Chat and provide the Chat ID with pin
                </p>

                <Formik
                    initialValues={{
                        chatUuid: '',
                        pin: '',
                        reuse: false,
                        avatar: '',
                        nickname: '',
                        member: '',
                    }}
                    validationSchema={Yup.object({
                        chatUuid: Yup.string()
                            .required('Required'),
                        pin: Yup.string()
                            .length(4, 'Must be 4 chracters pin')
                            .required('Required'),
                        reuse: Yup.boolean(),
                        avatar: Yup.string()
                            .when('reuse', {
                                is: false,
                                then: Yup.string().required('Required')
                            }),
                        nickname: Yup.string()
                            .when('reuse', {
                                is: false,
                                then: Yup.string().max(20, 'Must be 20 characters or less')
                                    .required('Required')
                            }),
                        member: Yup.string()
                            .when('reuse', {
                                is: true,
                                then: Yup.string().required('Required')
                            }),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        const token = await executeRecaptcha('JOIN_CHAT')
                        // grecaptcha.enterprise.ready(() => {
                        //     grecaptcha.enterprise.execute('6LdNcZ4aAAAAAM1jVqUzn7efsxTDJIQWEktJMGUu', {action: 'JOIN_CHAT'}).then((token) => {
                                track.event('join-chat', {'event_category': 'chat'})
                                window.http.post('/chats/join', {
                                    recaptcha: token,
                                    userUuid: ls.getUuid(),
                                    ...values
                                }).then((response) => {
                                    ls.addOrUpdateMember(response.data.member)
                                    ls.addOrUpdateChat(response.data.chat)
                                    dispatch(addOrUpdateChat(response.data.chat))
                                    dispatch(setActiveChatUuid(response.data.chat.uuid))
                                    dispatch(closeChatJoin())
                                }).catch((error) => {
                                    alert(error.response.data.error)
                                }).then(() => {
                                    setSubmitting(false)
                                })
                            // })
                        // })
                    }}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-3">
                                    <div className="flex items-center bg-gray-700 col-span-6 grid grid-cols-6 gap-3 p-2 sm:p-4 rounded-md">
                                        <div className="col-span-6">
                                            <label htmlFor="reuse" className="mt-3 inline-flex items-center cursor-pointer">
                                                <span className="relative">
                                                <span className="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                                                <span className={formik.values.reuse
                                                    ? `absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-purple-600 transform translate-x-full`
                                                    : `absolute block w-4 h-4 mt-1 ml-1 bg-white rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out`
                                                }>
                                                    <input
                                                        id="reuse"
                                                        type="checkbox"
                                                        className="disabled:opacity-50 absolute opacity-0 w-0 h-0"
                                                        disabled={formik.isSubmitting}
                                                        {...formik.getFieldProps('reuse')}
                                                    />
                                                </span>
                                                </span>
                                                <span className="ml-3 text-sm text-gray-300">Re-use existing member profile?</span>
                                            </label>
                                        </div>

                                        {!formik.values.reuse && (
                                            <>
                                                <div className="col-span-6 sm:col-span-2">
                                                    <label htmlFor="avatar" className="block text-gray-300 text-sm font-medium">Avatar</label>
                                                    <SelectImage
                                                        id="avatar"
                                                        items={avatars}
                                                        disabled={formik.isSubmitting}
                                                        {...formik.getFieldProps('avatar')}
                                                    />
                                                    {formik.touched.avatar && formik.errors.avatar ? (
                                                        <div className="text-red-400">{formik.errors.avatar}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-span-6 sm:col-span-4">
                                                    <label htmlFor="nickname" className="block text-gray-300 text-sm font-medium">Nickname</label>
                                                    <input
                                                        id="nickname"
                                                        type="text"
                                                        autoComplete="nickname"
                                                        className="disabled:opacity-50 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm text-gray-700 border-gray-300 rounded-md"
                                                        disabled={formik.isSubmitting}
                                                        {...formik.getFieldProps('nickname')}
                                                    />
                                                    {formik.touched.nickname && formik.errors.nickname ? (
                                                        <div className="text-red-400">{formik.errors.nickname}</div>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}

                                        {formik.values.reuse && (
                                            <div className="col-span-6">
                                                <label htmlFor="member" className="block text-gray-300 text-sm font-medium">Member</label>
                                                <SelectImageText
                                                    id="member"
                                                    items={members}
                                                    disabled={formik.isSubmitting}
                                                    {...formik.getFieldProps('member')}
                                                />
                                                {formik.touched.member && formik.errors.member ? (
                                                    <div className="text-red-400">{formik.errors.member}</div>
                                                ) : null}
                                            </div>
                                        )}

                                    </div>

                                    <div className="col-span-6 sm:col-span-4">
                                        <label htmlFor="chatUuid" className="block text-gray-300 text-sm font-medium">Chat ID</label>
                                        <input
                                            id="chatUuid"
                                            type="text"
                                            autoComplete="chatUuid"
                                            className="disabled:opacity-50 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm text-gray-700 border-gray-300 rounded-md"
                                            disabled={formik.isSubmitting}
                                            {...formik.getFieldProps('chatUuid')}
                                        />
                                        {formik.touched.chatUuid && formik.errors.chatUuid ? (
                                            <div className="text-red-400">{formik.errors.chatUuid}</div>
                                        ) : null}
                                    </div>

                                    <div className="col-span-6 sm:col-span-2">
                                        <label htmlFor="pin" className="block text-gray-300 text-sm font-medium">Security Pin (4 Characters)</label>
                                        <input
                                            id="pin"
                                            type="password"
                                            autoComplete="pin"
                                            className="disabled:opacity-50 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm text-gray-700 border-gray-300 rounded-md"
                                            disabled={formik.isSubmitting}
                                            {...formik.getFieldProps('pin')}
                                        />
                                        {formik.touched.pin && formik.errors.pin ? (
                                            <div className="text-red-400">{formik.errors.pin}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-800 px-2 pb-3 flex justify-between">
                                <button
                                    type="button"
                                    className="disabled:opacity-50 w-1/2 inline-flex justify-center rounded-md border border-transparent shadow-sm mx-2 px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none sm:text-sm"
                                    disabled={formik.isSubmitting}
                                    onClick={() => dispatch(closeChatJoin())}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="disabled:opacity-50 w-1/2 inline-flex justify-center rounded-md border border-transparent shadow-sm mx-2 px-4 py-2 bg-indigo-900 text-base font-medium text-white hover:bg-indigo-800 focus:outline-none sm:text-sm"
                                    disabled={formik.isSubmitting}
                                >
                                    {formik.isSubmitting
                                    ? <Loading iconSize="w-5" />
                                    : `Join`}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>

            </div>
        </Modal>    
    )
}

export default ChatJoin
