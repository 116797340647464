import { configureStore } from '@reduxjs/toolkit'
import chatsReducer from 'src/redux/slices/chatsSlice'
import openChatNewReducer from 'src/redux/slices/openChatNewSlice'
import openChatJoinReducer from 'src/redux/slices/openChatJoinSlice'
import openChatInviteReducer from 'src/redux/slices/openChatInviteSlice'
import openDeleteAllChatsReducer from 'src/redux/slices/openDeleteAllChatsSlice'
import openDeleteThisChatReducer from 'src/redux/slices/openDeleteThisChatSlice'
import openNotificationsReducer from 'src/redux/slices/openNotificationsSlice'
import openPhotoPreviewReducer from 'src/redux/slices/openPhotoPreviewSlice'
import openRequestNotificationReducer from 'src/redux/slices/openRequestNotificationSlice'
import activeChatUuidReducer from 'src/redux/slices/activeChatUuidSlice'
import messagesReducer from 'src/redux/slices/messagesSlice'
import notificationsReducer from 'src/redux/slices/notificationsSlice'

export default configureStore({
    reducer: {
        chats: chatsReducer,
        openChatNew: openChatNewReducer,
        openChatJoin: openChatJoinReducer,
        openChatInvite: openChatInviteReducer,
        openDeleteAllChats: openDeleteAllChatsReducer,
        openDeleteThisChat: openDeleteThisChatReducer,
        openNotifications: openNotificationsReducer,
        openPhotoPreview: openPhotoPreviewReducer,
        openRequestNotification: openRequestNotificationReducer,
        activeChatUuid: activeChatUuidReducer,
        messages: messagesReducer,
        notifications: notificationsReducer
    },
})