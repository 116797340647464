import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Main from './Main'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import store from 'src/redux/store'
import { Provider } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

ReactDOM.render(
    <Provider store={store}>
        <GoogleReCaptchaProvider
            reCaptchaKey="6LdNcZ4aAAAAAM1jVqUzn7efsxTDJIQWEktJMGUu"
            useEnterprise={true}
            scriptProps={{nonce:"ctwp"}}
        >
            <React.StrictMode>
                <Main />
            </React.StrictMode>
        </GoogleReCaptchaProvider>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
