import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { closPhotoPreview } from 'src/redux/slices/openPhotoPreviewSlice'
import Modal from 'src/components/Modal'

function PhotoPreview({photo}) {
    const dispatch = useDispatch()
    const ref = useRef(null)

    const handleKeyPressed = (event) => {
        if (event.key === 'Escape') {
            handleClose()
        }
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            handleClose()
        }
    }

    const handleClose = () => {
        dispatch(closPhotoPreview())
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressed, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleKeyPressed, true);
            document.removeEventListener('click', handleClickOutside, true);
        }
    })

    return (
        <Modal>
            <div ref={ref} className="max-w-full cursor-pointer">
                <img
                    src={photo}
                    onClick={handleClose}
                    alt=""
                />
            </div>
        </Modal>      
    )
}

PhotoPreview.propTypes = {
    photo: PropTypes.string
}

export default PhotoPreview
