import { createSlice } from '@reduxjs/toolkit'

export const openChatInviteSlice = createSlice({
    name: 'openChatInvite',
    initialState: {
        value: false,
    },
    reducers: {
        openChatInvite: (state) => {
            state.value = true
        },
        closeChatInvite: (state) => {
            state.value = false
        },
    },
})

export const { openChatInvite, closeChatInvite } = openChatInviteSlice.actions

export default openChatInviteSlice.reducer