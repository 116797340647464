import { format, formatRelative } from 'date-fns'
import ls from 'src/utils/localStorage'

const MESSAGE_STATUS_DELIVERING = 0
const MESSAGE_STATUS_DELIVERED = 1
const MESSAGE_STATUS_READ = 2

function isMobile() {
    let check = false;
    (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor);
    if (window.innerWidth <= 450) {
      check = true;
    }

    return check;
}

function findMember(chatUuid) {
  return ls.getMembers().find(member => {
    return member.chats.find(pChat => pChat.uuid === chatUuid)
  })
}

function findMemberChat(chatUuid) {
  const member = findMember(chatUuid)
  for (const memberChat of member.chats) {
      if (memberChat.uuid === chatUuid) {
          return memberChat
      }
  }
  return undefined
}

function toLocalDateTimeStringShort(datetime) {
  let data = formatRelative(new Date(datetime), new Date())
  if (data.match(/^today at /gi)) {
    return data.replace(/^today at /gi, '')
  }
  if (data.match(/ at /gi)) {
    return data.replace(/ at .*/gi, '')
  }
  return data
}

function toLocalDateString(datetime) {
  return formatRelative(new Date(datetime), new Date()).replace(/ at .*/gi, '')
}

function toLocalTimeString(datetime) {
  return format(new Date(datetime), 'h:mm aa')
}

function getAvatarPath(chat) {
  const member = findMember(chat.uuid)
  let avatar = null;
  if (chat.member.avatars.length === 1) {
    avatar = chat.member.avatars[0]
  } else if (member) {
    avatar = chat.member.avatars.find(avatar => {
        return avatar.pId !== member.id
    })
  } else {
    avatar = chat.member.avatars[0]
  }
  return avatar.path
}

function getMemberColor(memberId, memberColors) {
  let color = 'text-white'
  const colors = [
    'text-red-500',
    'text-yellow-500',
    'text-green-500',
    'text-blue-500',
    'text-indigo-500',
    'text-purple-500',
    'text-pink-500',
  ]

  if (memberColors[memberId] === undefined) {
    const usedColors = Object.keys(memberColors).map(item => memberColors[item])
    const remains = colors.filter(color => !usedColors.includes(color))
    if (remains.length > 0) {
      color = remains[Math.floor(Math.random() * remains.length)] 
    }
    memberColors[memberId] = color
  }

  return memberColors
}

function getMessagesRequireReadUpdate(chatUuid, memberId, shouldInstantRead) {
  return ls.getMessages(chatUuid).filter(item => memberId !== item.sender.id && shouldInstantRead && !item.isRead)
  .map(item => {
    if (shouldInstantRead && !item.isRead) {
      return {id: item.id, member_id: memberId, status: MESSAGE_STATUS_READ}
    }
    return {id: item.id, member_id: memberId, status: MESSAGE_STATUS_DELIVERED}
  })
}

function updateReadMessages(chatUuid, messages) {
  const messageIds = messages.map(item => item.id)
  const readMessages = ls.getMessages(chatUuid).map((item) => {
    if (messageIds.includes(item.id)) {
      return {...item, isRead: true}
    }
    return item
  })
  ls.setMessages(chatUuid, readMessages)
}

function getActiveChat(chats, uuid, init = false) {
  for (const chat of chats) {
    if (chat.uuid === uuid) {
      return chat
    }
  }

  return init ? {uuid: null} : undefined
}

function isGivenChatsInLocalChats(deleteChats, chats) {
  const uuids = deleteChats.chats.map(item => item.uuid)
  const senderChatUuids = deleteChats.senders.map(item => item.chat.uuid)
  const combined = [...new Set(uuids.concat(senderChatUuids))]

  for (const chat of chats) {
    if (combined.includes(chat.uuid)) {
      return true
    }
  }
  return false
}

function leaveDeletedChannels(deleteChats, chats) {
  if (deleteChats.length > 0) {
    const uuids = deleteChats.map(item => item.uuid)
    for (const chat of chats) {
      if (uuids.includes(chat.uuid)) {
        window.Echo.leaveChannel(`chat.${chat.uuid}.${chat.pin}`)
      }
    }
  }
}

function convertDeleteChatsToNotifications(deleteChats) {
  let notifications = []

  notifications = notifications.concat(deleteChats.chats.map(chat =>
    `Chat "${chat.name}" has been deleted by the host`
  ))

  notifications = notifications.concat(deleteChats.senders.map(sender =>
    `${sender.nick} has quit ${sender.chat.name} and deleted all his messages`
  ))

  return notifications
}

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    MESSAGE_STATUS_DELIVERING,
    MESSAGE_STATUS_DELIVERED,
    MESSAGE_STATUS_READ,
    isMobile,
    findMember,
    findMemberChat,
    toLocalDateTimeStringShort,
    toLocalDateString,
    toLocalTimeString,
    getAvatarPath,
    getMemberColor,
    getMessagesRequireReadUpdate,
    updateReadMessages,
    getActiveChat,
    isGivenChatsInLocalChats,
    leaveDeletedChannels,
    convertDeleteChatsToNotifications,
    urlBase64ToUint8Array
};