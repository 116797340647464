import { React } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'src/Routes'
import GoogleAnalytics from 'src/components/GoogleAnalytics'

function Main() {
    return (
        <div>
            <Router>
                <GoogleAnalytics />
                <Routes />
            </Router>
        </div>
    )
}

export default Main;
