function track(...args) {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    if (!window.gtag) {
        return;
    }

    window.gtag(...args);
}

function pageview(props) {
    track('config', 'G-32NZQBV7LB', props);
}

function event(type, props) {
    track('event', type, props);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    pageview,
    event
};
