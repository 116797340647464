import React from 'react'
import PropTypes from 'prop-types'

function Modal({children}) {
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>
        
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="w-full md:w-auto inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    {children}
                </div>
            </div>
        </div>        
    )
}

Modal.propTypes = {
    children: PropTypes.any
}

export default Modal
