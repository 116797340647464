import React from 'react'
import Modal from 'src/components/Modal'
import { useSelector, useDispatch } from 'react-redux'
import { closeNotifications } from 'src/redux/slices/openNotificationsSlice'
import { clearNotifications } from 'src/redux/slices/notificationsSlice'

function Notifications() {
    const dispatch = useDispatch()
    const notifications = useSelector((state) => state.notifications.value)

    const handleOk = () => {
        dispatch(clearNotifications())
        dispatch(closeNotifications())
    }

    return (
        <Modal>
            <div className="px-4 pt-5 pb-4 w-96 sm:w3/4 sm:p-6 sm:pb-4 space-y-4">
                <h3 className="text-center text-lg leading-6 font-medium text-white" id="modal-headline">
                    Notifications
                </h3>
                {notifications.map((notification, index )=> (
                    <div key={index} className="block rounded bg-gray-700 p-2 text-sm text-gray-400 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                        </svg>
                        {notification}
                    </div>
                ))}
            </div>
            <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button onClick={handleOk} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-900 text-base font-medium text-white hover:bg-indigo-800 focus:outline-none sm:text-sm">
                    OK, Got It
                </button>
            </div>
        </Modal>      
    )
}

export default Notifications
